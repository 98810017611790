var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-container",
            { staticClass: "pr-0 ma-0", attrs: { fluid: "" } },
            [
              _c(
                "v-stepper",
                {
                  staticStyle: { "box-shadow": "none", "padding-bottom": "0" },
                  model: {
                    value: _vm.stepperStep,
                    callback: function($$v) {
                      _vm.stepperStep = $$v
                    },
                    expression: "stepperStep"
                  }
                },
                [
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        { staticClass: "pa-0", attrs: { step: "1" } },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center flex-column" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title font-weight-bold primary--text"
                                },
                                [_vm._v("What is Object Distribution?")]
                              ),
                              _c(
                                "span",
                                { staticClass: "subtitle-2 text-center mt-5" },
                                [
                                  _vm._v(
                                    "Object distribution allows you to selectively send files, tables, and applications across instances or spaces."
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center mt-5" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "secondary--text subtitle-2 mr-1",
                                      attrs: { text: "", outlined: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.stepperStep = 2
                                        }
                                      }
                                    },
                                    [_vm._v(" Start ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.loading,
                                        color: "secondary",
                                        text: "",
                                        exact: "",
                                        to: {
                                          name: "snapshot-overview",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c("div", { staticClass: "d-flex justify-end" }, [
                            _c(
                              "span",
                              { staticClass: "caption font-weight-bold" },
                              [_vm._v("Step 1/4")]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mb-5" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title font-weight-bold primary--text"
                                },
                                [_vm._v("What do you want to distribute?")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { disabled: "", color: "secondary" },
                                  model: {
                                    value: _vm.whatToDistribute,
                                    callback: function($$v) {
                                      _vm.whatToDistribute = $$v
                                    },
                                    expression: "whatToDistribute"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "v-list-item",
                                            { on: { click: function() {} } },
                                            [
                                              _c("v-list-item-content", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column align-center justify-center",
                                                    staticStyle: {
                                                      "min-height": "80px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "overline mt-0 mb-1 d-flex justify-center text-uppercase"
                                                      },
                                                      [_vm._v("option 1")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "subtitle-2 primary--text text-uppercase text-center"
                                                      },
                                                      [
                                                        _vm._v(
                                                          'distribute all objects in "' +
                                                            _vm._s(
                                                              _vm.snapshotName
                                                            ) +
                                                            '"'
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass: "pa-0 mb-10",
                                              attrs: {
                                                disabled:
                                                  _vm.isStagingEmpty === true
                                              },
                                              on: { click: function() {} }
                                            },
                                            [
                                              _c("v-list-item-content", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column align-center justify-center",
                                                    staticStyle: {
                                                      "min-height": "80px"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "overline mt-0 mb-1 text-uppercase"
                                                      },
                                                      [_vm._v("option 2")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "subtitle-2 primary--text text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " distribute staged objects (" +
                                                            _vm._s(
                                                              _vm.numberOfStagedObjects
                                                            ) +
                                                            ") "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.whatToDistribute ===
                          _vm.distributionModeOptions.STAGED_OBJECTS
                            ? _c(
                                "div",
                                { staticClass: "mb-5 d-flex justify-center" },
                                [
                                  _c("span", { staticClass: "subtitle-2" }, [
                                    _vm._v("Review staged objects")
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "12", lg: "7" } }, [
                                _vm.isStagingEmpty === false &&
                                _vm.whatToDistribute ===
                                  _vm.distributionModeOptions.STAGED_OBJECTS
                                  ? _c("div", [
                                      _vm.stagingObjects.files.length
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column mb-5"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-space-between"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Files (" +
                                                          _vm._s(
                                                            _vm
                                                              .orderedStagedObjects
                                                              .files.length
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        text: "",
                                                        disabled: !_vm
                                                          .stagingObjects.files
                                                          .length,
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.clearStaging(
                                                            "files"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("clear_all")]
                                                      ),
                                                      _vm._v(
                                                        "Unstage all files "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-list",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-group",
                                                    {
                                                      staticClass: "pa-0 ma-0",
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.orderedStagedObjects
                                                        .files,
                                                      function(file) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: file.fid,
                                                            staticClass: "pa-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-avatar",
                                                              [
                                                                file.type ===
                                                                  "folder" ||
                                                                file.fileType ===
                                                                  2
                                                                  ? _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "folder"
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-file-outline"
                                                                        )
                                                                      ]
                                                                    )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text subtitle-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.filePath
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                staticClass:
                                                                  "ma-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeFromStaging(
                                                                          [
                                                                            file
                                                                          ],
                                                                          "file"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "error"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "cancel"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.stagingObjects.tables.length
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column mb-5"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-space-between"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Tables (" +
                                                          _vm._s(
                                                            _vm
                                                              .orderedStagedObjects
                                                              .tables.length
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        disabled: !_vm
                                                          .stagingObjects.tables
                                                          .length,
                                                        text: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.clearStaging(
                                                            "tables"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("clear_all")]
                                                      ),
                                                      _vm._v(
                                                        "Unstage all tables "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-list",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-group",
                                                    {
                                                      staticClass: "pa-0 ma-0",
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.orderedStagedObjects
                                                        .tables,
                                                      function(table) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: table.tid,
                                                            staticClass: "pa-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-avatar",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-table-large"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text subtitle-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        table.filePath
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                staticClass:
                                                                  "ma-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeFromStaging(
                                                                          [
                                                                            table
                                                                          ],
                                                                          "table"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "error"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "cancel"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.stagingObjects.applications.length
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-space-between"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Applications (" +
                                                          _vm._s(
                                                            _vm
                                                              .orderedStagedObjects
                                                              .applications
                                                              .length
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        disabled: !_vm
                                                          .stagingObjects
                                                          .applications.length,
                                                        text: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.clearStaging(
                                                            "applications"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("clear_all")]
                                                      ),
                                                      _vm._v(
                                                        "Unstage all applications "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _vm.applicationsAutoAdded === true
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      staticClass: "mt-3",
                                                      attrs: {
                                                        text: "",
                                                        prominent: "",
                                                        type: "success"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column",
                                                          staticStyle: {
                                                            "max-width": "800px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "primary--text subtitle-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Your recent applications have been automatically staged. This is in case you have recently installed packages / added new applications, which will be automatically shared."
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "caption font-weight-bold"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Consider unstaging applications you don't want to share."
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-list",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-group",
                                                    {
                                                      staticClass: "pa-0 ma-0",
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.stagingObjects
                                                        .applications,
                                                      function(application) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key:
                                                              application.aid,
                                                            staticClass: "pa-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-avatar",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "desktop_windows"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text subtitle-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        application.filePath
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                staticClass:
                                                                  "ma-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeFromStaging(
                                                                          [
                                                                            application
                                                                          ],
                                                                          "application"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "error"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "cancel"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column align-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center mt-5" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "secondary--text subtitle-2 mr-1",
                                      attrs: {
                                        text: "",
                                        outlined: "",
                                        disabled:
                                          _vm.whatToDistribute === undefined ||
                                          _vm.whatToDistribute === null
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.stepperStep = 3
                                        }
                                      }
                                    },
                                    [_vm._v("continue")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.loading,
                                        color: "secondary",
                                        text: "",
                                        exact: "",
                                        to: {
                                          name: "snapshot-overview",
                                          params: {
                                            oid: _vm.$route.params.oid,
                                            sid: _vm.$route.params.sid,
                                            iid: _vm.$route.params.iid,
                                            snid: _vm.$route.params.snid
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ],
                                1
                              ),
                              _vm.isStagingEmpty === true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-center"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-2 primary--text my-2 text-uppercase"
                                        },
                                        [_vm._v("or")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary--text subtitle-2",
                                              attrs: {
                                                text: "",
                                                to: {
                                                  name: "snapshot-files",
                                                  params: {
                                                    oid: _vm.$route.params.oid,
                                                    sid: _vm.$route.params.sid,
                                                    iid: _vm.$route.params.iid,
                                                    snid:
                                                      _vm.$route.params.snid,
                                                    localPath:
                                                      _vm.currentFileLocalPath,
                                                    fileArea: _vm.fileAreaType
                                                  }
                                                }
                                              }
                                            },
                                            [_vm._v("stage files")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary--text subtitle-2",
                                              attrs: {
                                                text: "",
                                                to: {
                                                  name: "snapshot-applications",
                                                  params: {
                                                    oid: _vm.$route.params.oid,
                                                    sid: _vm.$route.params.sid,
                                                    iid: _vm.$route.params.iid,
                                                    snid: _vm.$route.params.snid
                                                  }
                                                }
                                              }
                                            },
                                            [_vm._v("stage applications")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary--text subtitle-2",
                                              attrs: {
                                                text: "",
                                                to: {
                                                  name: "snapshot-tables",
                                                  params: {
                                                    oid: _vm.$route.params.oid,
                                                    sid: _vm.$route.params.sid,
                                                    iid: _vm.$route.params.iid,
                                                    snid: _vm.$route.params.snid
                                                  }
                                                }
                                              }
                                            },
                                            [_vm._v("stage tables")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      ),
                      _c("v-stepper-content", { attrs: { step: "3" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "caption font-weight-bold",
                                attrs: {
                                  color: "primary",
                                  text: "",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.stepperStep = 2
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-step-backward")
                                ]),
                                _vm._v("back ")
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "caption font-weight-bold" },
                              [_vm._v("Step 2/4")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center flex-column" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "title font-weight-bold primary--text"
                              },
                              [_vm._v("Whom do you want to distribute to?")]
                            ),
                            _c("span", { staticClass: "subtitle-2 mt-3" }, [
                              _vm._v(
                                "Select the target organization, space, and instances that will receive your objects."
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-column align-center mt-10"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c(
                                  "v-list-item-group",
                                  {
                                    attrs: { color: "secondary" },
                                    model: {
                                      value: _vm.targetDistribution,
                                      callback: function($$v) {
                                        _vm.targetDistribution = $$v
                                      },
                                      expression: "targetDistribution"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { justify: "center" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.fetchTargetData(
                                                      _vm
                                                        .distributionTargetOptions
                                                        .SAME_SPACE
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-item-content", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column align-center justify-center",
                                                      staticStyle: {
                                                        "min-height": "90px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "overline mb-1 text-uppercase"
                                                        },
                                                        [_vm._v("option 1")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "subtitle-2 primary--text px-3 text-uppercase text-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "use my current organization and space"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass: "pa-0 ma-0",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.fetchTargetData(
                                                      _vm
                                                        .distributionTargetOptions
                                                        .DIFFERENT_SPACE
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-item-content", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column align-center justify-center",
                                                      staticStyle: {
                                                        "min-height": "90px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "overline mb-1 text-uppercase"
                                                        },
                                                        [_vm._v("option 2")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "subtitle-2 primary--text text-uppercase text-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Another space/organization"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.targetDistribution ===
                            _vm.distributionTargetOptions.DIFFERENT_SPACE
                              ? _c(
                                  "div",
                                  { staticClass: "mt-10" },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "pb-3",
                                        attrs: {
                                          align: "center",
                                          justify: "center",
                                          "no-gutters": ""
                                        }
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "5" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "caption font-weight-medium mb-1"
                                            },
                                            [_vm._v("Target organization")]
                                          )
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "7" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.orgList,
                                                label: "Select Organization",
                                                "single-line": "",
                                                dense: "",
                                                outlined: "",
                                                "hide-details": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.getOrgData(false)
                                                }
                                              },
                                              model: {
                                                value: _vm.selectedOrg,
                                                callback: function($$v) {
                                                  _vm.selectedOrg = $$v
                                                },
                                                expression: "selectedOrg"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          align: "center",
                                          justify: "center",
                                          "no-gutters": ""
                                        }
                                      },
                                      [
                                        _c("v-col", { attrs: { cols: "5" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "caption font-weight-medium mb-1"
                                            },
                                            [_vm._v("Target space")]
                                          )
                                        ]),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "7" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.spaceList,
                                                label: _vm.orgFetching
                                                  ? "fetching spaces"
                                                  : "Select Space",
                                                disabled:
                                                  _vm.orgFetching ||
                                                  !_vm.selectedOrg,
                                                outlined: "",
                                                "persistent-hint": "",
                                                "single-line": "",
                                                "hide-details": "",
                                                dense: "",
                                                loading: _vm.orgFetching
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.getSpaceData(false)
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "item",
                                                    fn: function(spaceList) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "caption font-weight-medium"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                spaceList.item
                                                              ) + " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "prepend-item",
                                                    fn: function() {
                                                      return [
                                                        _c("v-text-field", {
                                                          staticClass: "px-4",
                                                          attrs: {
                                                            autofocus: "",
                                                            label: "Filter"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.spaceSearch,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.spaceSearch = $$v
                                                            },
                                                            expression:
                                                              "spaceSearch"
                                                          }
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                1552185668
                                              ),
                                              model: {
                                                value: _vm.selectedSpace,
                                                callback: function($$v) {
                                                  _vm.selectedSpace = $$v
                                                },
                                                expression: "selectedSpace"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedSpace &&
                            _vm.targetDistribution !== undefined
                              ? _c(
                                  "div",
                                  { staticClass: "mt-10" },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        model: {
                                          value: _vm.allOrSpecificInstances,
                                          callback: function($$v) {
                                            _vm.allOrSpecificInstances = $$v
                                          },
                                          expression: "allOrSpecificInstances"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            disabled:
                                              _vm.isEditorOfDistributed ===
                                                false ||
                                              _vm.targetSpaceType !==
                                                _vm.spaceTypes.EDUCATION_SPACE,
                                            label: _vm.shareWithAllLabel,
                                            value: "shareWithAllInstances"
                                          }
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            disabled:
                                              _vm.spaceInstanceList !==
                                              undefined
                                                ? !_vm.spaceInstanceList.length
                                                : true,
                                            label: _vm.shareWithOneOrSomeLabel,
                                            value: "shareWithSpecificInstances"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showInstancesTable &&
                            _vm.targetDistribution !== undefined &&
                            _vm.fetchingSpaceData === false
                              ? _c(
                                  "div",
                                  [
                                    _c("v-data-table", {
                                      attrs: {
                                        "show-select": "",
                                        headers: _vm.headers,
                                        items: _vm.instanceFetching
                                          ? []
                                          : _vm.spaceInstanceList,
                                        "single-select":
                                          _vm.isDevelopment === true,
                                        "items-per-page": -1,
                                        search: _vm.instanceSearch,
                                        "item-key": "iid",
                                        loading: _vm.fetchingSpaceData
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "top",
                                            fn: function() {
                                              return [
                                                _vm.isEditorOfDistributed ===
                                                  true &&
                                                _vm.targetSpaceType ===
                                                  _vm.spaceTypes.EDUCATION_SPACE
                                                  ? _c(
                                                      "v-alert",
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          type: "warning"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column",
                                                            staticStyle: {
                                                              "max-width":
                                                                "800px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Limited distribution"
                                                                )
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                " With this option, only the selected student "
                                                              ),
                                                              _vm.currentSpaceType ===
                                                                _vm.spaceTypes
                                                                  .EDUCATION_SPACE &&
                                                              _vm.isDevelopment
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "instance"
                                                                    )
                                                                  ])
                                                                : _vm.currentSpaceType ===
                                                                    _vm
                                                                      .spaceTypes
                                                                      .EDUCATION_SPACE &&
                                                                  !_vm.isDevelopment
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "instances"
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                " will receive your objects. If you want all student instances to receive the same contents, select "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.shareWithAllLabel
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "append-icon":
                                                      "mdi-magnify",
                                                    label: "Search",
                                                    "single-line": "",
                                                    "hide-details": ""
                                                  },
                                                  model: {
                                                    value: _vm.instanceSearch,
                                                    callback: function($$v) {
                                                      _vm.instanceSearch = $$v
                                                    },
                                                    expression: "instanceSearch"
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item.long_id",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(_vm._s(item.long_id))
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.description",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(_vm._s(item.description))
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1213751203
                                      ),
                                      model: {
                                        value: _vm.selectedInstances,
                                        callback: function($$v) {
                                          _vm.selectedInstances = $$v
                                        },
                                        expression: "selectedInstances"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedSpace &&
                            _vm.fetchingSpaceData === false &&
                            !_vm.spaceInstanceList.length &&
                            _vm.isEditorOfDistributed === true &&
                            _vm.targetSpaceType ===
                              _vm.spaceTypes.EDUCATION_SPACE
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-center",
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          prominent: "",
                                          outlined: "",
                                          type: "success"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex flex-column",
                                            staticStyle: {
                                              "max-width": "800px"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "subtitle-1 ma-0 font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "Distributing before inviting"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ma-0" },
                                              [
                                                _vm._v(
                                                  " Because you haven't invited any students yet, shared material will be available to new students after you have invited them. "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            (_vm.selectedSpace ||
                              _vm.currentOrgSpaceAreTargets) &&
                            _vm.fetchingSpaceData === false &&
                            !_vm.spaceInstanceList.length &&
                            (_vm.isEditorOfDistributed === false ||
                              _vm.targetSpaceType ===
                                _vm.spaceTypes.RESEARCH_SPACE)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-center",
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          prominent: "",
                                          text: "",
                                          type: "warning",
                                          "max-width": "600"
                                        }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "subtitle-1 ma-0 font-weight-bold"
                                          },
                                          [_vm._v("No targets available")]
                                        ),
                                        _c("ul", [
                                          _vm.isEditorOfDistributed === false
                                            ? _c("li", [
                                                _vm._v(
                                                  " You have insufficient privileges to distribute to everyone. "
                                                )
                                              ])
                                            : _vm._e(),
                                          !_vm.spaceInstanceList.length
                                            ? _c("li", [
                                                _vm._v(
                                                  " There are no instances you are an editor of in this "
                                                ),
                                                _vm.currentSpaceType ===
                                                _vm.spaceTypes.EDUCATION_SPACE
                                                  ? _c("span", [
                                                      _vm._v("course")
                                                    ])
                                                  : _vm.currentSpaceType ===
                                                    _vm.spaceTypes
                                                      .RESEARCH_SPACE
                                                  ? _c("span", [
                                                      _vm._v("research project")
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("space")
                                                    ]),
                                                _vm._v(". ")
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-5 d-flex justify-center align-center"
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "secondary--text subtitle-2 mr-1",
                                attrs: {
                                  text: "",
                                  outlined: "",
                                  loading:
                                    _vm.orgFetching === true ||
                                    _vm.fetchingSpaceData === true,
                                  disabled: _vm.disableContinueInstanceSelection
                                },
                                on: { click: _vm.setSelectedSpaceTableStatus }
                              },
                              [_vm._v("continue")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.loading,
                                  color: "secondary",
                                  text: "",
                                  exact: "",
                                  to: {
                                    name: "snapshot-overview",
                                    params: {
                                      oid: _vm.$route.params.oid,
                                      sid: _vm.$route.params.sid,
                                      iid: _vm.$route.params.iid,
                                      snid: _vm.$route.params.snid
                                    }
                                  }
                                }
                              },
                              [_vm._v("Cancel")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "caption font-weight-bold",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.stepperStep = 3
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-step-backward")
                                  ]),
                                  _vm._v("back ")
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "caption font-weight-bold" },
                                [_vm._v("Step 3/4")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center flex-column mb-5"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title font-weight-bold primary--text"
                                },
                                [
                                  _vm._v(
                                    "How do you want to share your objects?"
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-list-item-group",
                            {
                              staticClass: "mt-10",
                              attrs: { color: "secondary" },
                              model: {
                                value: _vm.distributionStrategy,
                                callback: function($$v) {
                                  _vm.distributionStrategy = $$v
                                },
                                expression: "distributionStrategy"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    justify: "space-between",
                                    "no-gutters": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.customDistribution === true
                                          }
                                        },
                                        [
                                          _c("v-list-item-content", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column align-center justify-center"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-information-outline"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Distribute all your objects, and if a similar object exists in the target instance then it will be overwritten."
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-uppercase"
                                                      },
                                                      [_vm._v("overwrite")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    width: "290",
                                                    height: "150",
                                                    src: require("@/assets/overwrite.svg")
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.customDistribution === true
                                          },
                                          on: { click: function() {} }
                                        },
                                        [
                                          _c("v-list-item-content", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column align-center justify-center"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-information-outline"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Among the objects you are distributing, only those that do not exist in the target instances will be shared."
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "distribute extra"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    width: "290",
                                                    height: "150",
                                                    src: require("@/assets/distribute_extra.svg")
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: {
                                            disabled:
                                              _vm.customDistribution === true
                                          },
                                          on: { click: function() {} }
                                        },
                                        [
                                          _c("v-list-item-content", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column align-center justify-center"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-information-outline"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Erease all objects in the target instance, and replace them with the objects that you are sharing."
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "clear target and replace"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    width: "290",
                                                    height: "150",
                                                    src: require("@/assets/clear_target.svg")
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.snapshotTargetBeforeDistributing === true
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mt-5",
                                  attrs: {
                                    type: "success",
                                    outlined: "",
                                    prominent: ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-column",
                                      staticStyle: { "max-width": "800px" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ma-0 font-weight-bold"
                                        },
                                        [_vm._v("Backup Snapshot")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          "A snapshot of the current state of each target instance will be taken. This will allow users to recover or restore overwritten files."
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm.snapshotTargetBeforeDistributing === false &&
                              _vm.targetSpaceTablesAreEnabled
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mt-5",
                                  attrs: {
                                    type: "warning",
                                    text: "",
                                    prominent: ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-column",
                                      staticStyle: { "max-width": "800px" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ma-0 font-weight-bold"
                                        },
                                        [_vm._v("Backup Snapshot")]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          " Since tables are enabled in the target space, target instance(s) will "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("not")]
                                        ),
                                        _vm._v(
                                          " be snapshotted before distribution for increased performance. This is recommended only for distributing completely new material to avoid unrecoverable data loss in the target(s). You can change this setting in the "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Custom Distribution")]
                                        ),
                                        _vm._v(
                                          " section, however distribution will be significantly slower if snapshotting before distribution is enabled. "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm.snapshotTargetBeforeDistributing === false &&
                              !_vm.targetSpaceTablesAreEnabled
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mt-5",
                                  attrs: {
                                    type: "warning",
                                    text: "",
                                    prominent: ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-column",
                                      staticStyle: { "max-width": "800px" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ma-0 font-weight-bold"
                                        },
                                        [_vm._v("Backup Snapshot")]
                                      ),
                                      _c("p", [
                                        _vm._v(" Target instance(s) will "),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("not")]
                                        ),
                                        _vm._v(
                                          " be snapshotted before distribution. You can change this setting in the "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Custom Distribution")]
                                        ),
                                        _vm._v(" section. ")
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "ml-5" }, [
                            _c(
                              "div",
                              [
                                _c("v-switch", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.customDistribution
                                                ? "subtitle-1 font-weight-bold primary--text"
                                                : "subtitle-1"
                                            },
                                            [_vm._v("Custom Distribution")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ]),
                                  model: {
                                    value: _vm.customDistribution,
                                    callback: function($$v) {
                                      _vm.customDistribution = $$v
                                    },
                                    expression: "customDistribution"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.customDistribution
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { attrs: { xs3: "" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "font-weight-medium",
                                        attrs: {
                                          label:
                                            "Snapshot target before distributing"
                                        },
                                        model: {
                                          value:
                                            _vm.snapshotTargetBeforeDistributing,
                                          callback: function($$v) {
                                            _vm.snapshotTargetBeforeDistributing = $$v
                                          },
                                          expression:
                                            "snapshotTargetBeforeDistributing"
                                        }
                                      }),
                                      _c("v-divider", {
                                        staticClass: "mt-3",
                                        staticStyle: { "max-width": "500px" },
                                        attrs: { color: "grey" }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.whatToDistribute ===
                                    _vm.distributionModeOptions.ALL_OBJECTS ||
                                  _vm.selectedObjectTypes.includes("workspace")
                                    ? _c(
                                        "div",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "font-weight-medium",
                                            attrs: {
                                              disabled:
                                                _vm.whatToDistribute ===
                                                  _vm.distributionModeOptions
                                                    .STAGED_OBJECTS &&
                                                _vm.workspaceCheck &&
                                                _vm.selectedObjectTypes.includes(
                                                  "workspace"
                                                ),
                                              label: "Workspace objects"
                                            },
                                            model: {
                                              value: _vm.workspaceCheck,
                                              callback: function($$v) {
                                                _vm.workspaceCheck = $$v
                                              },
                                              expression: "workspaceCheck"
                                            }
                                          }),
                                          _c("v-select", {
                                            staticClass: "fileSelect",
                                            staticStyle: { width: "500px" },
                                            attrs: {
                                              disabled: !_vm.workspaceCheck,
                                              items: _vm.distributionModesArray,
                                              "persistent-hint": "",
                                              "return-object": "",
                                              "single-line": ""
                                            },
                                            model: {
                                              value: _vm.workspaceDistMode,
                                              callback: function($$v) {
                                                _vm.workspaceDistMode = $$v
                                              },
                                              expression: "workspaceDistMode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.whatToDistribute ===
                                    _vm.distributionModeOptions.ALL_OBJECTS ||
                                  _vm.selectedObjectTypes.includes("personal")
                                    ? _c(
                                        "div",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "font-weight-medium",
                                            attrs: {
                                              disabled:
                                                _vm.whatToDistribute ===
                                                  _vm.distributionModeOptions
                                                    .STAGED_OBJECTS &&
                                                _vm.personalCheck &&
                                                _vm.selectedObjectTypes.includes(
                                                  "personal"
                                                ),
                                              label: "Personal files"
                                            },
                                            model: {
                                              value: _vm.personalCheck,
                                              callback: function($$v) {
                                                _vm.personalCheck = $$v
                                              },
                                              expression: "personalCheck"
                                            }
                                          }),
                                          _c("v-select", {
                                            staticClass: "fileSelect",
                                            staticStyle: { width: "500px" },
                                            attrs: {
                                              disabled: !_vm.personalCheck,
                                              items: _vm.distributionModesArray
                                            },
                                            model: {
                                              value: _vm.personalFilesDistMode,
                                              callback: function($$v) {
                                                _vm.personalFilesDistMode = $$v
                                              },
                                              expression:
                                                "personalFilesDistMode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.whatToDistribute ===
                                    _vm.distributionModeOptions.ALL_OBJECTS ||
                                  _vm.selectedObjectTypes.includes(
                                    "application"
                                  )
                                    ? _c(
                                        "div",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "font-weight-medium",
                                            attrs: {
                                              disabled:
                                                _vm.whatToDistribute ===
                                                  _vm.distributionModeOptions
                                                    .STAGED_OBJECTS &&
                                                _vm.applicationCheck &&
                                                _vm.selectedObjectTypes.includes(
                                                  "application"
                                                ),
                                              label: "Application files"
                                            },
                                            model: {
                                              value: _vm.applicationCheck,
                                              callback: function($$v) {
                                                _vm.applicationCheck = $$v
                                              },
                                              expression: "applicationCheck"
                                            }
                                          }),
                                          _c("v-select", {
                                            staticClass: "fileSelect",
                                            staticStyle: { width: "500px" },
                                            attrs: {
                                              disabled: !_vm.applicationCheck,
                                              items: _vm.distributionModesArray
                                            },
                                            model: {
                                              value:
                                                _vm.applicationFilesDistMode,
                                              callback: function($$v) {
                                                _vm.applicationFilesDistMode = $$v
                                              },
                                              expression:
                                                "applicationFilesDistMode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.whatToDistribute ===
                                    _vm.distributionModeOptions.ALL_OBJECTS ||
                                  _vm.selectedObjectTypes.includes("table")
                                    ? _c(
                                        "div",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "font-weight-medium",
                                            attrs: {
                                              disabled:
                                                _vm.whatToDistribute ===
                                                  _vm.distributionModeOptions
                                                    .STAGED_OBJECTS &&
                                                _vm.tableCheck &&
                                                _vm.selectedObjectTypes.includes(
                                                  "table"
                                                ),
                                              label: "Tables"
                                            },
                                            model: {
                                              value: _vm.tableCheck,
                                              callback: function($$v) {
                                                _vm.tableCheck = $$v
                                              },
                                              expression: "tableCheck"
                                            }
                                          }),
                                          _c("v-select", {
                                            staticClass: "fileSelect",
                                            staticStyle: { width: "500px" },
                                            attrs: {
                                              disabled: !_vm.tableCheck,
                                              items: _vm.distributionModesArray
                                            },
                                            model: {
                                              value: _vm.tablesDistMode,
                                              callback: function($$v) {
                                                _vm.tablesDistMode = $$v
                                              },
                                              expression: "tablesDistMode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "mt-5",
                                attrs: { justify: "center", align: "center" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "secondary--text subtitle-2 mr-1",
                                    attrs: {
                                      text: "",
                                      outlined: "",
                                      disabled:
                                        _vm.customDistribution === false &&
                                        _vm.distributionStrategy === undefined
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.stepperStep = 5
                                      }
                                    }
                                  },
                                  [_vm._v(" continue ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      disabled: _vm.loading,
                                      color: "secondary",
                                      text: "",
                                      exact: "",
                                      to: {
                                        name: "snapshot-overview",
                                        params: {
                                          oid: _vm.$route.params.oid,
                                          sid: _vm.$route.params.sid,
                                          iid: _vm.$route.params.iid,
                                          snid: _vm.$route.params.snid
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v("Cancel")]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "5" } },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "caption font-weight-bold",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.stepperStep = 4
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-step-backward")
                                  ]),
                                  _vm._v("back ")
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "caption font-weight-bold" },
                                [_vm._v("Step 4/4")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center flex-column mb-5"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title font-weight-bold primary--text"
                                },
                                [
                                  _vm._v(
                                    "Notify target users about your distribution with a message"
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("v-checkbox", {
                            attrs: { label: "Notify Target Users" },
                            model: {
                              value: _vm.notifyTargetUsers,
                              callback: function($$v) {
                                _vm.notifyTargetUsers = $$v
                              },
                              expression: "notifyTargetUsers"
                            }
                          }),
                          _vm.notifyTargetUsers
                            ? _c("v-textarea", {
                                attrs: { rows: "2", "auto-grow": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [_vm._v("Your message")]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3634785665
                                ),
                                model: {
                                  value: _vm.notificationText,
                                  callback: function($$v) {
                                    _vm.notificationText = $$v
                                  },
                                  expression: "notificationText"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.stepperStep === 5
        ? _c("v-card-actions", [
            _c(
              "div",
              {
                staticClass: "d-flex justify-center align-center",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary--text subtitle-2 ml-1",
                    attrs: {
                      loading: _vm.loading,
                      disabled:
                        _vm.notifyTargetUsers && !_vm.notificationText.length,
                      outlined: "",
                      text: ""
                    },
                    on: { click: _vm.distribute }
                  },
                  [_vm._v("share objects")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.loading,
                      color: "secondary",
                      text: "",
                      exact: "",
                      to: {
                        name: "snapshot-overview",
                        params: {
                          oid: _vm.$route.params.oid,
                          sid: _vm.$route.params.sid,
                          iid: _vm.$route.params.iid,
                          snid: _vm.$route.params.snid
                        }
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "v-alert",
                  {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.error,
                      type: "error",
                      icon: "warning",
                      outlined: ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.errorContent))]
                ),
                _c(
                  "v-alert",
                  {
                    staticClass: "mt-3",
                    attrs: {
                      value: _vm.inputError,
                      type: "error",
                      icon: "warning",
                      outlined: ""
                    }
                  },
                  [
                    _vm._v(
                      "Please select the instances you want to share the current snapshot with"
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }